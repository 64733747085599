export function initPagination() {
  $('.pagination select.page-select').on('input', function(ev) {
    if (ev.target && ev.target.value) {
      ev.preventDefault();
      ev.target.setAttribute('disabled', '');
      window.location.href = ev.target.value;
    }
  });
}

export function initPressRequestsModal() {
  $('#pressRequestModal').on('show.bs.modal', function(ev) {
    if (ev.target && ev.relatedTarget && ev.relatedTarget.dataset.modalContent) {
      const el = ev.target.querySelector('#pressRequestDetails');
      if (!el) return;
      try {
        const req = JSON.parse(ev.relatedTarget.dataset.modalContent);
        console.log(req);
        if (req) {
          el.innerHTML = req.mailup_error
            ? `
            <dl class="alert alert-danger" role="alert">
              <dt><h6>MailUp error</h6></dt>
              <dd class="lead mb-0">${req.mailup_error}</dd>
            </dl>`
            : '';
          el.innerHTML += `
            <dl>
              <dt class="text-muted"><h6>First name</h6></dt>
              <dd class="lead">${req.first_name || '-'}</dd>
              <dt class="text-muted"><h6>Last name</h6></dt>
              <dd class="lead">${req.last_name || '-'}</dd>
              <dt class="text-muted"><h6>Email</h6></dt>
              <dd class="lead"><a href="${req.email ? 'mailto:' + req.email : ''}">${req.email || '-'}</a></dd>
              <dt class="text-muted"><h6>Phone</h6></dt>
              <dd class="lead"><a href="${req.phone ? 'tel:' + req.phone : ''}">${req.phone || '-'}</a></dd>
              <hr class="border-secondary">
              <dt class="text-muted"><h6>Newspaper</h6></dt>
              <dd class="lead">${req.newspaper || '-'}</dd>
              <dt class="text-muted"><h6>Newspaper phone</h6></dt>
              <dd class="lead"><a href="${req.newspaper_phone ? 'tel:' + req.newspaper_phone : ''}">${req.newspaper_phone || '-'}</a></dd>
              <dt class="text-muted"><h6>Newspaper address</h6></dt>
              <dd class="lead">${req.newspaper_address || '-'}</dd>
              <hr class="border-secondary">
              <dt class="text-muted"><h6>Message</h6></dt>
              <dd><p>${req.message || '-'}</p></dd>
            </dl>
          `;
          const title = ev.target.querySelector('#pressRequestModalTitle');
          if (title) {
            title.innerHTML = (req.first_name || '') + ' ' + (req.last_name || '');
          }
        }
      } catch(err) {
        el.innerHTML = `
          <h2 class="text-center lead mt-4 mb-5"><em>Error parsing request data</em></h2>
          <p>${err.message}</p>
        `;
        console.log(err);
      }
    }
  });
}

export function initUsersRequestsModal() {
  $('#usersRequestModal').on('show.bs.modal', function(ev) {
    if (ev.target && ev.relatedTarget && ev.relatedTarget.dataset.modalContent) {
      const el = ev.target.querySelector('#usersRequestDetails');
      if (!el) return;
      try {
        const req = JSON.parse(ev.relatedTarget.dataset.modalContent);
        console.log(req);
        if (req) {
          el.innerHTML = '<dl>\n';
          if (req.reporter?.name || req.reporter?.email) {
            if (req.reporter?.name) {
              el.innerHTML +=`<dt class="text-muted"><h6>Name</h6></dt>
              <dd class="lead">${req.reporter.name}</dd>
              `
            }
            if (req.reporter?.accountId) {
              el.innerHTML +=`<dt class="text-muted"><h6>#Account</h6></dt>
              <dd class="text-small">${req.reporter.accountId}</dd>
              `
            }
            if (req.reporter?.userId) {
              el.innerHTML +=`<dt class="text-muted"><h6>#User</h6></dt>
              <dd class="text-small">${req.reporter.userId}</dd>
              `
            }
            if (req.reporter?.milleId) {
              el.innerHTML +=`<dt class="text-muted"><h6>#Mille</h6></dt>
              <dd class="text-small">${req.reporter?.milleId}</dd>
              `
            }
            if (req.reporter?.email) {
              el.innerHTML +=`<dt class="text-muted"><h6>Email</h6></dt>
              <dd class="text-small">${req.reporter.email}</dd>
              `
            }
            el.innerHTML +='<hr class="border-secondary">\n';
            
          }
          el.innerHTML += `<dt class="text-muted"><h6>Type</h6></dt>
              <dd><p>${req.type || '-'}</p></dd>
              <dt class="text-muted"><h6>Argument</h6></dt>
              <dd><p>${req.argument || '-'}</p></dd>
              <dt class="text-muted"><h6>Message</h6></dt>
              <dd><p class="lead">${req.description || '-'}</p></dd>
            </dl>
          `;
          const title = ev.target.querySelector('#usersRequestModalTitle');
          if (title) {
            title.innerHTML = (req.reporter?.name || 'N/D') + ' - ' + (new Date(req.createdAt).toLocaleDateString());
          }
        }
      } catch(err) {
        el.innerHTML = `
          <h2 class="text-center lead mt-4 mb-5"><em>Error parsing request data</em></h2>
          <p>${err.message}</p>
        `;
        console.log(err);
      }
    }
  });
}
