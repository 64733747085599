const changeAvatar = () => {
  const genderChoice = document.getElementById("GenderChoice")
  if (genderChoice) {
    const femaleInput = document.getElementById("Female")
    const maleInput = document.getElementById("Male")
    // const otherInput = document.getElementById("OtherGender")
    const womanAvatar = document.querySelector(".gender-woman")
    const manAvatar = document.querySelector(".gender-man")
    // const otherAvatar = document.querySelector(".gender-other")
    // const inputAvatar = document.querySelector(".avatar-input-default")
    // const uploadedAvatar = document.querySelector(".avatar-uploaded")

    femaleInput.addEventListener("click", function() {
      womanAvatar.style.display = "flex";
      // inputAvatar.value = "https://shop-rockin1000-develop.s3.amazonaws.com/original_febc8abd280c01814b66.png"
      if ( manAvatar.style.display !== "none" ) {
        manAvatar.style.display = "none";
      }
      // if ( otherAvatar.style.display !== "none" ) {
      //   otherAvatar.style.display = "none";
      // }
    })

    
    maleInput.addEventListener("click", function() {
      manAvatar.style.display = "flex";
      // inputAvatar.value = "https://shop-rockin1000-develop.s3.amazonaws.com/original_99f3808725a2e7b9072b.png"
      if ( womanAvatar.style.display !== "none" ) {
        womanAvatar.style.display = "none";
      }
      // if ( otherAvatar.style.display !== "none" ) {
      //   otherAvatar.style.display = "none";
      // }
    })

    // otherInput.addEventListener("click", function() {
    //   otherAvatar.style.display = "flex";
    //   // inputAvatar.value = "https://shop-rockin1000-develop.s3.amazonaws.com/original_1ca61add0794fff6dcb8.png"
    //   if ( manAvatar.style.display !== "none" ) {
    //     manAvatar.style.display = "none";
    //   }
    //   if ( womanAvatar.style.display !== "none" ) {
    //     womanAvatar.style.display = "none";
    //   }
    // })
  }
}

export { changeAvatar }
