const showHistoryText = () => {
  const expandBtn = document.querySelectorAll(".espandi-testo")
  const historyParagraphs = document.querySelectorAll(".testo-storia")
  if (expandBtn) {
    expandBtn.forEach(function callback(btn, index) {
      btn.addEventListener("click", function() {
        if (historyParagraphs[index].style.display === "none") {
          $(historyParagraphs[index]).show(200);
          $(this).find('.icon-container').html('<i class="fa fa-minus-circle mr-3" aria-hidden="true"></i>')
        } else {
          $(historyParagraphs[index]).hide(200);
          $(this).find('.icon-container').html('<i class="fa fa-plus-circle mr-3" aria-hidden="true"></i>')
        }
      })
    })
  }
}

export { showHistoryText }
