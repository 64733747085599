import $ from 'jquery'
import jstz from 'jstz'
import countries from '../data/timezone2country.json'

export function scrollUp() {
  const upBtn = document.getElementById("upBtn")
  if (upBtn) {
    upBtn.addEventListener("click", function() {
      window.scrollTo({top: 0, behavior: "smooth"});
      // document.documentElement.scrollTop = 0;
    });
  }
}

const NEWSLETTER_MODAL_ID = 'nlModal'
const NEWSLETTER_TOGGLE_ID = 'nlToggle'
const NEWSLETTER_FOOTER_EMAIL_ID = 'nlFooterEmail'
const NEWSLETTER_ERROR_ID = 'nlError'

function newsletterSubmit(onSuccess, onError, ev) {
  if (ev.currentTarget instanceof HTMLFormElement) {
    ev.preventDefault()
    const formData = new FormData(ev.currentTarget)
    const formEl = ev.currentTarget
    formEl.classList.add('loading')
    $.ajax({
      type: "POST",
      url: "/it/newsletter",
      processData: false,
      data: JSON.stringify(Object.fromEntries(formData)),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function(data){
        window.GTM__fireEvent?.('newsletter_signup_success')
        formEl.classList.remove('loading')
        formEl.classList.add('success')
        onSuccess?.(data)
      },
      error: function(errMsg) {
        window.GTM__fireEvent?.('newsletter_signup_error')
        formEl.classList.remove('loading')
        onError?.(errMsg)
      }
  });
  }
}

function resolveCountry(tz) {
  try {
    return countries[tz.split('/')[1]]
  } catch(_) {
    return ''
  }
}

function showAlert(mountEl, message, type = 'info') {
  const alertEl = document.createElement('div')
  alertEl.classList.add('alert', 'alert-' + type, 'alert-inline', 'alert-dismissible', 'fade', 'show')
  alertEl.role = 'alert'
  alertEl.innerHTML = `${message}<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>`
  mountEl.insertAdjacentElement('afterbegin', alertEl)
}

function setUpModal(nlModal) {
  const nlForm = nlModal.querySelector('form')
  if (nlForm) {
    const nlEmail = nlForm.querySelector('[name="email"]')
    const footerEmail = document.getElementById(NEWSLETTER_FOOTER_EMAIL_ID)
    const nlCountry = nlForm.querySelector('[name="country"]')
    const nlAlertMount = document.getElementById(NEWSLETTER_ERROR_ID)
    if (nlCountry.childElementCount <= 1) {
      const countryOptions = Array.from(new Set(Object.values(countries))).sort((a, b) => a.localeCompare(b))
      for (const country of countryOptions) {
        const option = document.createElement('option')
        option.value = option.innerHTML = country
        nlCountry.insertAdjacentElement('beforeend', option)
      }
    }

    nlForm.addEventListener('submit', (ev) => {
      $(nlAlertMount).children('.alert')?.alert('close')
      newsletterSubmit(
        () => {
          nlForm.reset()
          showAlert(nlAlertMount, nlForm.dataset.subscriptionSuccess, 'success')
        },
        () => {
          showAlert(nlAlertMount, nlForm.dataset.subscriptionError, 'warning')
        },
        ev
      )
    })

    $(nlModal).on('show.bs.modal', () => {
      const country = resolveCountry(jstz.determine().name())
      for (const option of nlCountry.children) {
        if (option.value === country) {
          option.selected = true
          break
        }
      }
      nlEmail.value = footerEmail?.value
    })

    $(nlModal).on('hide.bs.modal', () => {
      $(nlAlertMount).children('.alert')?.alert('close')
      footerEmail.value = ''
      nlForm.reset()
    })
  }
}

export function initNewsletterForm() {
  const newsletterModal = document.getElementById(NEWSLETTER_MODAL_ID)
  const newsletterModalToggle = document.getElementById(NEWSLETTER_TOGGLE_ID)
  if (newsletterModal && newsletterModalToggle) {
    try {
      newsletterModalToggle.addEventListener('click', () => {
        $(newsletterModal).modal('show');
      });
      setUpModal(newsletterModal);
      if (window.location.search.match(/\bnl\b/)) $(newsletterModal).modal('show');
    } catch(err) {
      console.error('Unable to create newsletter modal: ' + (err.message ?? String(err)));
    }
  }
}
