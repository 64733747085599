// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";
import flatpickr from "flatpickr";
import { Dropzone } from "dropzone";
Dropzone.autoDiscover = false;


// Internal imports, e.g:
import { datePicker } from '../plugins/flatpickr';
import { scrollToFaqs } from '../pages/contact';
import { seeSubmenuItems } from '../pages/navbar';
import { openNavMobile } from '../pages/navbar';
import { scrollUp, initNewsletterForm } from '../pages/footer';
import { showHistoryText } from '../pages/history';
import { setUserRole } from '../pages/userwork';
import { changeAvatar } from '../pages/userinfo';
import { avatarInput } from '../pages/avatar_form';
import { googleSignup } from '../pages/google_signup';
import { facebookSignup } from '../pages/facebook_signup';
import { initSliders, initPlayerThumbs, initPressFormScroll } from '../pages/press';
import { GTM__firePage, GTM__checkEventToFire, GTM__fireEvent, GTM__checkRegistrationCookie } from '../utility/tag-manager-events.js'
// import {  } from '../utility/utilities.js'
import { initPagination, initPressRequestsModal, initUsersRequestsModal } from '../utility/admin.js';


document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  datePicker();
  scrollToFaqs();
  seeSubmenuItems();
  openNavMobile();
  scrollUp();
  initNewsletterForm();
  showHistoryText();
  setUserRole();
  changeAvatar();
  avatarInput();
  googleSignup();
  facebookSignup();
  initSliders();
  initPlayerThumbs();
  initPressFormScroll('form');
  initPressFormScroll('pressForm');
  initPagination();
  initPressRequestsModal();
  initUsersRequestsModal();
  window.GTM__firePage = GTM__firePage
  window.GTM__fireEvent = GTM__fireEvent
  window.GTM__checkEventToFire = GTM__checkEventToFire
  window.GTM__checkRegistrationCookie = GTM__checkRegistrationCookie
});


