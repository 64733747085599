const scrollToFaqs = () => {
  const goToFaqs = document.getElementById("to-faqs")
  if (goToFaqs) {
    goToFaqs.addEventListener("click", function() {
      const faqs = document.getElementById("faqs")
      faqs.scrollIntoView({behavior: "smooth"});
      // document.documentElement.scrollTop = 0;
    });
  }
}


export { scrollToFaqs };
