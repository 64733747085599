const avatarInput = () => {
  const avatarInput = document.getElementById("avatarInput")
  if (avatarInput) {
    document.getElementById("avatarInputBtn")
    .addEventListener('click',function(){
      avatarInput.click();
    },false);
    avatarInput.onchange = function() {
      const uploadedAvatar = document.querySelector(".avatar-uploaded")
      if ( uploadedAvatar.style.display === "none" ) {
        uploadedAvatar.style.display = "block";
      }
    }
  }
}

export { avatarInput }
