
const seeSubmenuItems = () => {
  const arrowNav = document.querySelectorAll(".arrow-nav")
  const submenus = document.querySelectorAll(".submenu")
  if (arrowNav) {
    arrowNav.forEach(function callback(arrow, index) {
      arrow.addEventListener("click", function() {
        if (submenus[index].style.display === "none") {
          submenus[index].style.display = "block";
        } else {
          submenus[index].style.display = "none";
        }
      })
    })
  }
}

const seeDropdownItems = () => {
  const arrowNav = document.querySelectorAll(".arrow-nav")
  const submenus = document.querySelectorAll(".submenu")
  if (arrowNav) {
    arrowNav.forEach(function callback(arrow, index) {
      arrow.addEventListener("click", function() {
        if (submenus[index].style.display === "none") {
          submenus[index].style.display = "block";
        } else {
          submenus[index].style.display = "none";
        }
      })
    })
  }
}

// const openNavMobile = () => {
//   const toggler = document.querySelector(".navbar-toggler")
//   const nav = document.querySelector(".responsive-nav")
//   const closeNav = document.querySelector(".close-nav")
//   const openNav = document.querySelector(".open-nav")
//   if (toggler) {
//     toggler.addEventListener("click", function() {
//       if (nav.style.display === "none") {
//         nav.style.display = "block";
//         closeNav.style.display = "block"
//         openNav.style.display = "none"
//       } else {
//         nav.style.display = "none";
//         openNav.style.display = "block"
//         closeNav.style.display = "none"
//       }
//     })
//   }
// }

const openNavMobile = () => {
  const toggler = document.querySelector(".navbar-toggler")
  const nav = document.querySelector(".responsive-nav")
  const closeNav = document.querySelector(".close-nav")
  const openNav = document.querySelector(".open-nav")
  if (toggler) {
    toggler.addEventListener("click", function() {
      if (!nav.classList.contains("visible-nav")) {
        nav.classList.add("visible-nav");
        closeNav.style.display = "block"
        openNav.style.display = "none"
      } else {
        nav.classList.remove("visible-nav");
        openNav.style.display = "block"
        closeNav.style.display = "none"
      }
    })
  }
}



export { seeSubmenuItems, openNavMobile }
