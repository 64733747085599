import flatpickr from "flatpickr";
import $ from 'jquery';

const datePicker = () => {
  if ($('.datepicker')) {
    flatpickr(".datepicker", {
      dateFormat: "Y-m-d H:i",
    });
  }
};

export { datePicker };
