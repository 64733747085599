import $ from 'jquery';
import { Splide } from '@splidejs/splide';
import Plyr from 'plyr';
import icons from '../../assets/images/video-player/player_icons.svg';

const THUMB_CLASSNAME = 'player-toggle';
const PLAYER_MODAL_ID = 'playerModal';
const VIDEO_ID = 'playerMount';

function sourceFromElement(el) {
  if (el.dataset.playerSrc) {
    return {
      type: 'video',
      title: el.dataset.playerTitle || el.title,
      poster: el.dataset.playerPoster,
      sources: [
        {
          src: el.dataset.playerSrc,
          type: 'video/mp4'
        }
      ],
    };
  }
}

function elementPlayerSource(el) {
  return (el && el.dataset.playerSrc) || '';
}

function buildPlayerModal(modalId, videoId) {
  const playerModalEl = document.createElement('div');
  playerModalEl.id = modalId;
  playerModalEl.classList.add('modal', 'player-modal', 'fade');
  playerModalEl.innerHTML = `
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <video id="${videoId}"></video>
        </div>
      </div>
    </div>
  `;

  document.body.appendChild(playerModalEl);
  return playerModalEl;
}

function instantiatePlayerModal() {
  let playerModal = document.getElementById(PLAYER_MODAL_ID);
  if (!playerModal || !playerModal.querySelector('#'+VIDEO_ID)) {
    if (playerModal) {
      playerModal.parentElement.removeChild(playerModal);
    }
    playerModal = buildPlayerModal(PLAYER_MODAL_ID, VIDEO_ID);
  }
  const videoEl = playerModal.querySelector('#'+VIDEO_ID);
  if (!videoEl) throw Error('Unable to mount player modal');
  const player = new Plyr(videoEl, { iconUrl: icons });
  document.addEventListener('turbolinks:visit', player.destroy, { once: true });
  const $playerModal = $(playerModal);
  $playerModal
    .on('show.bs.modal', (ev) => {
      if (player.source !== elementPlayerSource(ev.relatedTarget)) {
        player.source = sourceFromElement(ev.relatedTarget);
        $playerModal.find(".modal-body").attr("data-title", player.config.title || "");
      }
    })
    .on('shown.bs.modal', () => {
      player.play();
    })
    .on('hide.bs.modal', player.pause)
    .modal({ show: false });
  player.on("ended", () => {
    $playerModal.modal("hide");
  });
  player.on("controlshidden", () => {
    $playerModal.addClass("hide-close");
  });
  player.on("controlsshown", () => {
    $playerModal.removeClass("hide-close");
  });
}

const TEXT_SCROLL_READ_SPEED = 2;
const TEXT_SCROLL_RESET_SPEED = 0.5;
const TEXT_SCROLL_MOBILE_DELAY = 2000;
const TEXT_SCROLL_MOBILE_THROTTLE = 3000;
const IS_MOBILE = document.documentElement.dataset.device === "mobile";


function setScrollTextScroller(scrollerEl) {
  const scrollerContent = document.createElement('span');
  scrollerContent.style.display = 'block';
  scrollerContent.dataset.scrollTextScroller = "";
  scrollerContent.textContent = scrollerEl.innerHTML;
  scrollerEl.innerHTML = "";
  scrollerEl.append(scrollerContent);
  return scrollerContent;
}

function getScroller(eventOrElement) {
  const scrollerEl = eventOrElement instanceof Event
    ? eventOrElement.target
    : eventOrElement;
  return scrollerEl instanceof HTMLElement && scrollerEl;
}

function setupScrollText(sliderEl, splide) {
  if (sliderEl) {
    for (const slide of sliderEl.querySelectorAll('[data-scroll-text]')) {
      const scroller = setScrollTextScroller(slide.querySelector(slide.dataset.scrollText));
      if (!IS_MOBILE) {
        slide.addEventListener("mouseenter", startScroll.bind(undefined, scroller));
        slide.addEventListener("mouseleave", resetScroll.bind(undefined, scroller));
      }
    }
    if (IS_MOBILE) {
      splide.on("visible", (slideObj) => {
        const scroller = slideObj.slide.querySelector('[data-scroll-text-scroller]');
        setTimeout(startScroll.bind(undefined, scroller) , TEXT_SCROLL_MOBILE_DELAY);
      });
      splide.on("hidden", (slideObj) => {
        const scroller = slideObj.slide.querySelector('[data-scroll-text-scroller]');
        resetScroll(scroller);
      });
    }
  }
}

function getDurationSeconds(el, multiplier) {
  const ratio = el.offsetHeight / el.parentElement.offsetHeight;
  return ratio >= 1 ? multiplier * ratio : 0;
}

function startScroll(eventOrElement) {
  const scrollerEl = getScroller(eventOrElement);
  if (scrollerEl instanceof HTMLElement) {
    const duration = getDurationSeconds(scrollerEl, TEXT_SCROLL_READ_SPEED);
    if (duration) {
      if (IS_MOBILE) {
        setTimeout(() => {
          scrollerEl.style.transition = `margin-top ${duration}s linear`;
          scrollerEl.style.marginTop = `-${scrollerEl.offsetHeight}px`;
          scrollerEl.addEventListener("transitionend", rollbackPosition);
        }, TEXT_SCROLL_MOBILE_THROTTLE);
      } else {
        scrollerEl.style.transition = `margin-top ${duration}s linear`;
        scrollerEl.style.marginTop = `-${scrollerEl.offsetHeight}px`;
        scrollerEl.addEventListener("transitionend", rollbackPosition);
      }
    } else {
      scrollerEl.style.transition = "";
      resetScroll(scrollerEl);
    }
  }
}

function rollbackPosition(eventOrElement) {
  const scrollerEl = getScroller(eventOrElement);
  if (scrollerEl) {
    scrollerEl.style.transition = "";
    scrollerEl.style.marginTop = scrollerEl.parentElement.offsetHeight + "px";
    requestAnimationFrame(() => {
      resetScroll(scrollerEl);
      requestAnimationFrame(() => {
        scrollerEl.addEventListener("transitionend", startScroll);
      });
    });
  }
}

function resetScroll(eventOrElement) {
  const scrollerEl = getScroller(eventOrElement);
  if (scrollerEl instanceof HTMLElement) {
    scrollerEl.removeEventListener("transitionend", startScroll);
    scrollerEl.removeEventListener("transitionend", rollbackPosition);
    const duration = getDurationSeconds(scrollerEl, TEXT_SCROLL_RESET_SPEED);
    scrollerEl.style.transition = `margin-top ${duration}s linear`;
    scrollerEl.style.marginTop = "";
  }
}

export function initPlayerThumbs() {
  const thumbs = document.getElementsByClassName(THUMB_CLASSNAME);
  if (thumbs.length) {
    instantiatePlayerModal();
    for (const el of thumbs) {
      el.dataset.toggle = 'modal';
      el.dataset.target = '#'+PLAYER_MODAL_ID;
    }
  }
}

export function initSliders() {
  if (Splide) {
    Splide.defaults = {
      pagination: false,
      arrowPath: 'M 11.25 2.25 L 28.75 20.25 L 11.25 37.75',
      gap: 15,
    };

    for (const elId of ['articleSlider', 'videoSlider', 'coverageSlider']) {
      const el = document.getElementById(elId);
      if (el) {
        const slider = new Splide(el);
        setupScrollText(el, slider);
        slider.mount();
      }
    }
  }
}

export function initPressFormScroll(formId) {
  const $formEl = $('#'+formId);
  if ($formEl.length) {
    $("a[href='#"+formId+"']").on("click", function($ev) {
      $ev.preventDefault();
      const $scrollEl = $('html, body');
      const topNav = document.getElementById('top-nav') || 0;
      const mainNav = document.getElementById('main-nav') || 0;
      const scrollTo = $formEl.offset().top - ((topNav && topNav.offsetHeight) + (mainNav && mainNav.offsetHeight));
      const offset = Math.abs($scrollEl.scrollTop() - scrollTo);
      const duration = 500 + Math.round(500 * (offset / window.innerHeight));
      $scrollEl.animate({
        scrollTop: scrollTo,
      }, duration, function() {
        const focusInput = $ev.currentTarget.dataset.focusOnScroll;
        if (focusInput) {
          $("[name='" + focusInput + "']").trigger('focus');
        }
      });
    });
  }
}
