const setUserRole = () => {
  const userWorkDiv = document.getElementById("UserWork")
  if (userWorkDiv) {
    const musicianBtn = document.getElementById("MusicianChoice")
    const volunteerBtn = document.getElementById("VolunteerChoice")
    const fanBtn = document.getElementById("FanChoice")
    const musicianForm = document.getElementById("MusicianWork")
    const volunteerForm = document.getElementById("VolunteerWork")
    const fanForm = document.getElementById("FanWork")
    // const roleTag = document.getElementById("user_type")
    if (musicianBtn) {
      musicianBtn.addEventListener("click", function () {
        musicianForm.style.display = "block";
        if (volunteerForm.style.display === "block") {
          volunteerForm.style.display = "none";
        }
        if (fanForm.style.display === "block") {
          fanForm.style.display = "none";
        }
      })
    }
    if (volunteerBtn) {
      volunteerBtn.addEventListener("click", function () {
        // roleTag.value = "VOLUNTEER"
        volunteerForm.style.display = "block";
        if (musicianForm.style.display === "block") {
          musicianForm.style.display = "none";
        }
        if (fanForm.style.display === "block") {
          fanForm.style.display = "none";
        }
      })
    }
    if (fanBtn) {
      fanBtn.addEventListener("click", function () {
        // roleTag.value = "VOLUNTEER"
        fanForm.style.display = "block";
        if (musicianForm.style.display === "block") {
          musicianForm.style.display = "none";
        }
        if (volunteerForm.style.display === "block") {
          volunteerForm.style.display = "none";
        }
      })
    }
  }
}

export { setUserRole }
