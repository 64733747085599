// TODO ( 1 ) FIXME EVENTI che in caso di primo render vengono sparati due volte
// TODO ( 2 ) MANCA ANCORA IL LOGIN DA GESTIRE IN MODO SIMILE ALLA registrazione con il cookie


const eventsINFO = {
    'registration_click': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'fire also on login click',
        'HOW': 'on button click',
    },
    'login_password': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'pagina /welcome ',
        'HOW': 'server side tag',
    },
    'email_or_social': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'pagina /welcome ',
        'HOW': 'server side tag',
    },
    'start_registration_process': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'pagina /signup ',
        'HOW': 'server side tag',
    },
    'registration_user_signed_up': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'user registered in DB | NB is possible to add a variable [MAIL,FACEBOOK,GOOGLE]',
        'HOW': 'cookie messo da backend (userRegisteredEvent) mandato evento da frontend ',
    },
    'registration_verify_email': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'verification email: don\'t fire with social login | also already registered user with no confirmed email after login arrive here after login',
        'HOW': 'server side tag',
    },
    'registration_more_info': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'ask some more info: avatar, gender and city |  already registered user with no gender or city after login arrive here after login',
        'HOW': 'server side tag',
    },
    'registration_role_info': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'ask if FUN, MUSICIAN or AMBASSADOR: in case of musician the instrument and the role |  already registered user with no user_type or musician with no istruments arrive here after login | !!IMPORTANT!! if the musician miss the link for evaluation will not land here after login, this info will be asked in APP',
        'HOW': 'server side tag',
    },
    'registration_download_app': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'page to ask user to donwload the app |  if FUN, MUSICIAN or AMBASSADOR: in case of musician the instrument and the role |  already registered user connecting with mobile will arrive here ',
        'HOW': 'server side tag',
    },
    'registration_download_click': {
        'category': 'registration_funnel',
        'type': 'GAEvent',
        'note': 'on page /download-app',
        'HOW': 'on button click',
    },    
    'login_general': {
        'category': 'login',
        'type': 'GAEvent',
        'note': 'not fired when user registered for the first time ',
        'HOW': 'redirect a login_success',
    },
    'contact_form_sent_success': {
        'category': 'contact_us',
        'type': 'GAEvent',
        'note': 'we should add the motivation (category of the form): if they contact us for a bug is bad, if they contact us for a partnership is good',
        'HOW': 'server side tag',
    },
    'contact_form_sent_error': {
        'category': 'contact_us',
        'type': 'GAEvent',
        'HOW': 'server side tag',
    },
    'newsletter_signup_success': {
        'category': 'newsletter_signup',
        'type': 'GAEvent',
        'HOW': 'Ajax callback'
    },
    'newsletter_signup_error': {
        'category': 'newsletter_signup',
        'type': 'GAEvent',
        'HOW': 'Ajax callback'
    },
    'app_clicked': {
        'category': 'app_download',
        'type': 'GAEvent'
    },
    'content_shared': {
        'category': 'sharing',
        'type': 'GAEvent'
    },
    'open_social_channel': {
        'category': 'social',
        'type': 'GAEvent',
        'HOW': 'on button click',
    },  
    'equity_external_click': {
        'category': 'equity',
        'type': 'GAEvent',
        'HOW': 'on button click',
    }, 
    'equity_internal_click': {
        'category': 'equity',
        'type': 'GAEvent',
        'HOW': 'on button click',
    },           
}

// info passate da backend
var user_id = document.getElementsByTagName("html")[0].getAttribute('user-id') == '' ? null : document.getElementsByTagName("html")[0].getAttribute('user-id')
var user_type = document.getElementsByTagName("html")[0].getAttribute('user-type') == '' ? null : document.getElementsByTagName("html")[0].getAttribute('user-type')
var domain = document.getElementsByTagName("html")[0].getAttribute('domain') == '' ? null : document.getElementsByTagName("html")[0].getAttribute('domain')


// al cambio di stato controllo non sia cambiato nulla
export const checkUserUpdate = () => {
    user_id = document.getElementsByTagName("html")[0].getAttribute('user-id') == '' ? null : document.getElementsByTagName("html")[0].getAttribute('user-id')
    user_type = document.getElementsByTagName("html")[0].getAttribute('user-type') == '' ? null : document.getElementsByTagName("html")[0].getAttribute('user-type')
}

// utility
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const delete_cookie = (name, path, domain) => {
    if (getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

// al cambio di pagina controllo se ci sono cambiamenti es user logout e sparo il cambio di pagina
document.addEventListener("turbolinks:before-render", function (event) { // TODO GTM__firePage FIRED TWO TIMES FOR ALL PAGE BUT NOT THE FIRST RENDER
    // var urlPage = '/' + window.location.pathname.replace('/it/', '').replace('/de/', '').replace('/en/', '').replace('/fr/', '').replace('/es/', '').replace('/pr/', '')
    // console.log(urlPage)
    checkUserUpdate()
})

// cambio di pagina
export const GTM__firePage = () => {
    var _OBJ = {
        'login': user_id != 'anonymous' ? 'Y' : 'N',
        'userId': user_id != 'anonymous' ? btoa(user_id) : undefined,
        'userType': user_id != 'anonymous' ? user_type : 'ANONYMOUS',
        'page': window.location.pathname,
        'context': 'www',
        'analyticsConsent': 'Y',
        'mktgConsent': 'Y',
    }
    if (typeof window.dataLayer === 'undefined') {
        window.dataLayer = []
        window.dataLayer.push(_OBJ);
    } else {
        window.dataLayer.push(_OBJ);
    }
}

// funzione generica per sparare gli eventi
export const GTM__fireEvent = (event, label) => {
    var _OBJ = {
        'eventCategory': eventsINFO[event]['category'],
        'eventAction': event,
        'event': eventsINFO[event]['type']
    }
    if (label) {
        _OBJ['eventLabel'] = label
    }
    if (typeof window.dataLayer === 'undefined') {
        window.dataLayer = []
        window.dataLayer.push(_OBJ);
    } else {
        window.dataLayer.push(_OBJ);
    }
}

// al render il server passa degli eventi
export const GTM__checkEventToFire = () => {
    try {
        if (typeof window.TAG_eventsToFire !== 'undefined' && typeof JSON.parse(window.TAG_eventsToFire) === 'object' && JSON.parse(window.TAG_eventsToFire).length > 0) {
            JSON.parse(window.TAG_eventsToFire).forEach(event => {
                GTM__fireEvent(event)
            });
        }
    } catch (error) {
        console.error(error);
    }
}


// in alcuni casi come login e regidstrazione non posso passare eventi diretti dal controller, vedi rimalzo tra i vari controllere quindi uso cookie che poi cancello
export const GTM__checkRegistrationCookie = () => {
    var checkValueCookieRegistration = getCookie('userRegisteredEvent')

    if (checkValueCookieRegistration) {
        GTM__fireEvent('registration_user_signed_up') //TODO capire se posso passare variabile per tipo di signup
        delete_cookie('userRegisteredEvent', '/', domain)
    }
}
